
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');
var Promise = require('promise');
var TimelineMax = require('greensock').TimelineMax;


var neededFacebookPosts = 4;


var containerSelector = '.social-feed';

// Working example avec D&M Fan Club
// Changer pour le hashtag qu'on veut pour filtrer
var necessary_hashtag = '#mdgfog';
var nextPageUrl;

var loadFacebookPosts = function(facebookPosts){
	if(facebookPosts){
		$.ajax({
			url: nextPageUrl,
			type: 'GET',
			dataType: 'JSON',
			success:function(data){
				if(data.data.length == 0){
					// console.warn("Only found " + facebookPosts.length + " posts matching hashtag " + necessary_hashtag);
					// console.dir(facebookPosts);
					return false;
				}

				if(data.paging.next){
					nextPageUrl = data.paging.next;
				}
				prepareFacebookData(data.data, facebookPosts);
			},
			error:debugAjaxFailure
		});
	}
	else {
		$.ajax({
			url: ns.moduleUrl + '/Facebook.php',
			type: 'POST',
			dataType: 'JSON',
			data: {
				edge:'posts'
			},
			success:function(data){
				if(data.paging.next){
					nextPageUrl = data.paging.next;
				}
				prepareFacebookData(data.data);
			},
			error:debugAjaxFailure
		});
	}
};

var prepareFacebookData = function(data, carry){
	var facebookPosts = (carry || []).concat(data.filter(function(post){
		if(post.message && post.message.indexOf(necessary_hashtag) != -1){
			post.hashes = post.message.match(/(#|\uFF03)([A-Za-z0-9_\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff]+)/g);
			return true;
		}
		return false;
	}));
	facebookPosts = facebookPosts.slice(0, 4);
	if(facebookPosts.length < neededFacebookPosts){
		loadFacebookPosts(facebookPosts);
	} else {
		buildSocialFeed(facebookPosts);
	}
};

var buildSocialFeed = function(facebookPosts){
	var container = $(containerSelector+' .mason-container');
	if(container.length === 0) return;

	container = facebookPosts.reduce(function(container, post){
				
		var block = $('<a class="mason-block" href="http://facebook.com/' + post.id + '" target="_blank"><div class="overlay"></div><div class="contenu"></div></a>');

		if(post.type == 'photo'){
			$.ajax({
				url: ns.moduleUrl + '/Facebook.php',
				type: 'POST',
				dataType: 'JSON',
				data: {
					edge: '/' + post.object_id,
					singlePost:true
				},
				success:function(data){
					var image = data.source;
					var bg = "url('" + image + "')";
					block.attr("style", block.attr("style") + " background-image:" + bg + ';');
				},
				error:debugAjaxFailure
			});
		}

		// Ajout des classes nécessaires
		if(i == 0){
			block.addClass('highlight');
		}
		else if(i == 1){
			block.addClass('long');
		}

		// Building content
		var contenu = block.find('.contenu');
		contenu.append('<i class="social-icon social-icon-facebook"></i>');

		if(post.hashes.length > 1){
			contenu.append('<h3>' + post.hashes[0].substring(1).replace(/_/g,' ') + '</h3>');
		}

		post.hashes.forEach(function(hash, index, array){
			post.message = post.message.replace(hash, '<span class="hash">' + hash + '</span>');
		});

		contenu.append('<div class="sep"></div>');
		contenu.append('<p class="text">' + post.message + '</p>');


		container.append(block);
		return container;
		
	}, container);

	$(containerSelector).show();
    container.isotope(ns.isotopeSettings);

	/*$('.mason-container').isotope({
		itemSelector: '.mason-block',
		masonry: {
			columnWidth: '.mason-block',
			gutter: '.gutter-sizer'
		}
	});/**/

	var masonBlocks = container.find('.mason-block').not('.title');
	var moveContent = function(e){
		var block = $(e.currentTarget);
		
		block.off('mouseenter.mdg_mason');
		var defaultHeight = block.find('.social-icon').outerHeight() + block.find('h3').outerHeight() + block.find('.sep').outerHeight();
		block.find('.contenu').height(block.find('.text').outerHeight() + defaultHeight);
	}

	masonBlocks.on('mouseenter.mdg_mason', moveContent);

	masonBlocks.on('mouseleave.mdg_mason', function(e){
		var block = $(e.currentTarget);
		block.on('mouseenter.mdg_mason', moveContent);
		var defaultHeight = block.find('.social-icon').outerHeight() + block.find('h3').outerHeight() + block.find('.sep').outerHeight();
		block.find('.contenu').height(defaultHeight);
	});
};

var debugAjaxFailure = function(x, exception){
    var message;
    var statusErrorMap = {
        '400' : "Server understood the request but request content was invalid.",
        '401' : "Unauthorised access.",
        '403' : "Forbidden resouce can't be accessed",
        '404' : "File not found",
        '500' : "Internal Server Error.",
        '503' : "Service Unavailable"
    };

    if (x.status) {
        message = statusErrorMap[x.status];

        if(!message)
            message="Unknow Error, status code : " + x.status;                  
    }
    else if(exception=='parsererror'){
        message="Error.\nParsing JSON Request failed.";
    }
    else if(exception=='timeout'){
        message="Request Timed out.";
    }
    else if(exception=='abort'){
        message="Request was aborted by the server";
    }
    else {
        message="Unknow Error, exception : " + exception;
    }

    //console.log(message);
}

ns.docReady.then(function(){

	var cnt = $(containerSelector);
	if(cnt.length ===0) return;
	cnt.hide();

	//DÉSACTIVÉ : marche pas online (2015-06-01)
	//loadFacebookPosts()

});
