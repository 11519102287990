//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

require('validate');
	
var contactForm;
var postTo = '/feedback';

var getPost = function() {
	var post = {};
	contactForm.find(':input').not('[type=radio]').each(function(){
		var inp = $(this);
		var inpVal = inp.val();
		if(inp.attr('type')=='checkbox'){
			if(inp.is(':checked')){
				inpVal = inpVal;
			} else {
				inpVal = null;
			}
		}
		
		post[inp.attr('name')] = inpVal;
		
	});
	
	contactForm.find(':radio').filter(':checked').each(function(){
		var inp = $(this);
		var inpVal = inp.val();
		post[inp.attr('name')] = inpVal;
	});
	
	return post;
};



var onSubmitContact = (function(){
	
	var submitted = false;
	var gs = require('greensock');
	
	return function(e) {
		e.preventDefault();
		if(submitted) return;
		
		submitted = true;
		
		var valid = contactForm.validationEngine('validate');
		//console.log(valid);

		if(valid){
			
			var data = getPost();
			
			var msgContainer = $('#contactMsg').empty();
			var msg = $('<div class="alert">')
			var btnClose = msg.find('.close');
			
			contactForm.stop().fadeTo(200, 0.5);
			
			$.ajax({
				type :'POST',
				url: postTo,
				data : data,
				dataType : 'json',
				success: function(res) {
					//console.dir(res);
					if(res.error) {
						
						msg.addClass('alert-error');
						contactForm.stop().fadeTo(900, 1);
						submitted = false;
					} else {
						contactForm.stop().fadeTo(900, 0);
						msg.removeClass('alert-success');
					}
											
					msg.prepend(res.response).appendTo(msgContainer);
					msgContainer.fadeIn(1000, function(){
						gs.TweenMax.to($(window), 400, {scrollTo:'#contactMsg', ease:gs.Sine.easeInOut});
					});
					
				},
				error: function(res, txStatus, err){
					console.dir(err);
					console.log('error posting message');
					console.log(res.responseText);
				}
				
			});
		} else {
			contactForm.on('click.hideprompts', function(){
				contactForm.validationEngine('hide');
				contactForm.off('.hideprompts');
			});
		}
		
	};
})();

ns.docReady.then(function(){
	
	contactForm = $('#contactForm');

	contactForm.on('submit.mdg', onSubmitContact);
	
});
