//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

require('jquery-colorbox');

ns.docReady.then(function(){
	
	var container = $('.showcase .mason-container');
	//console.log(container.length);
	if(container.length === 0) return;
	//container.isotope(ns.isotopeSettings);

	container.isotope({
		itemSelector: '.mason-block',
		masonry: {
			columnWidth: '.mason-block',
			gutter: '.gutter-sizer'
		}
	});

	var masonBlocks = container.find('.mason-block').not('.title');
	var moveContent = function(e){
		var block = $(e.currentTarget);
		
		block.off('mouseenter.mdg_mason');
		var defaultHeight = block.find('h3').height() + block.find('.sep').height();
		
		block.find('.contenu').height(block.find('.text').height() + block.find('.blue-btn').height() + 65 + defaultHeight);
	}

	masonBlocks.on('mouseenter.mdg_mason', moveContent);
	masonBlocks.on('mouseleave.mdg_mason', function(e){
		var block = $(e.currentTarget);
		block.on('mouseenter.mdg_mason', moveContent);
		var defaultHeight = block.find('h3').height() + block.find('.sep').height();
		block.find('.contenu').height(defaultHeight);
	});

	var filterByMarket = $('#filterByMarket');
	var market = filterByMarket.find('option').first().val();
	filterByMarket.on('change.mdg', function(){
		market = filterByMarket.val();
		container.isotope({
			filter: '[data-type='+market+']'
		});

		//projets secondaires
		$('.secondary-projects').hide();
		$('.secondary-projects-'+market).show();

	}).trigger('change.mdg');

	$('[data-sublistbtn]').each(function(i, el){
		el = $(el);
		var listId = el.data('sublistbtn');
		var subList = $('[data-sublist="'+listId+'"]');
		var hasItems = $('div', subList).length > 0;
		if(hasItems){
			el.addClass('more');
			el.on('click.mdg', function(){
				subList.toggle();
			});
		}
		
	});

	var colorboxSettings = $.extend({}, ns.colorboxSettings);

	colorboxSettings.onOpen = function(){
		var cboxTemplate = $('#'+$(this).data('colorbox-template'));
		cboxTemplate.find('script[type="popup/template"]').each(function(i, item){
			var item = $(item);
			item.replaceWith(item.html());
		});
	};

	colorboxSettings.onComplete = function(){
		var cboxTemplate = $('#'+$(this).data('colorbox-template'));
		var slider = cboxTemplate.find('.slider');
		if(slider.length > 0 && !slider.data('neutrino')){
			slider.neutrino({
				tweener: require('greensock').TweenMax,
				hasArrows: true,
				isResponsive:{},
				arrowsOutsideSlider:true,
				timer: 0,
				hasTimer: false,
				onAfterTransition:function(){
					//console.log('cb resize');
					// var neutrino = this;
					// var currentSlide = $(this.slides[this.currentIndex]);
					// var imgUrl = currentSlide.css('background-image').replace(/"/g,"").replace(/url\(|\)$/ig, "");
					// var image = new Image();
					// image.src = imgUrl;
					// image.onload = function(){
					// 	$(neutrino.root).parent().width(image.width);
					// };
					$.colorbox.resize();
				}
			});
		}
		$(window).trigger('resize');
	};

	colorboxSettings.onCleanup = function(){
		var cboxTemplate = $('#'+$(this).data('colorbox-template'));
		if(cboxTemplate.find('.slider').length > 0){
			cboxTemplate.find('.slider').neutrino('stopTimer');
		}
	}

	$('.colorbox-pf').colorbox(colorboxSettings);

});