//refactor : 2015-06-01

	"use strict";

	var name = 'mdg';

	var Promise = require('promise');
	// var $ = require('jquery');
	var $ = require('jquery');
	var ns = window[name] = (window[name] || {});


	ns.isotopeSettings = {
		itemSelector: '.mason-block',
		masonry: {
			columnWidth: 400,
			gutter: 48
		}
	};

	ns.colorboxSettings = {
		inline:true,
		fixed: true,
		maxHeight: $(window).height() * 0.9,
		href:function(){
			return '#' + $(this).data('colorbox-template');
		}
	};

	ns.dates = [
		{
			fr:['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
			en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			es: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
		},
		{
			fr:['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'],
			en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			es: ['enero', 'feb', 'marzo', 'abr', 'mayo', 'jun', 'jul', 'agosto', 'sept', 'oct', 'nov', 'dec']
		},
		{
			fr:['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
			en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			es: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado']
		}
	];


	ns.window = $(window);

	ns.getBody = (function(){
		var body;
		return function(){
			return body = body || $('body');
		};
	})();

	window.requestAnimFrame = (function(){
		return window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function( callback ){
				window.setTimeout(callback, 1000 / 60);
			};
	})();

	//passe le jQuery ajax ds Promise
	ns.ajax = function(params){
		return Promise.resolve($.ajax(params));
	};

	/**
	 * generates unique id
	 */		
	ns.getUniqueID = function(){
		var id = 0;
		return function(){
			return 'uid' + id++;
		}
	}();

	ns.docReady = new Promise(function(resolve, reject) {
		document.addEventListener('DOMContentLoaded', resolve);
	}).catch(e => {
		console.error(e);
	});


	module.exports = ns;