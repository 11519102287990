//refactor : 2015-06-01
/**
	
	navig dans les sous-sections (menu de gauche)

*/

'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');
var Promise = require('promise');
var TimelineMax = require('greensock').TimelineMax;


var inDeferred;
var outDeferred;

var showNewSection = function(section) {
	//console.log(section);
	var def = new Promise(function(resolve, reject){
		section.show();
		var tl = section.data('tl');
		inDeferred = function(){
			resolve(section);
		};
		tl.reverse(0);
	});
	return def;
};

var inFinish = function(){
	inDeferred && inDeferred();
};
var outFinish = function(){
	outDeferred && outDeferred();
};

var toggleSection = function(oldSection, newSection) {
	var def = new Promise(function(resolve, reject){
		var tl = oldSection.data('tl');
		outDeferred = function(){
			oldSection.hide();
			resolve(newSection);
		};
		tl.play();
	});
	return def.then(showNewSection);
};


ns.docReady.then(function(){

	var allSections = $('[data-section]').hide();
	var activeSection = allSections.filter(':first').show();

	allSections.each(function(i, el){
		el = $(el);
		var tl = new TimelineMax({
			onComplete: outFinish,
			onReverseComplete: inFinish
		});
		tl.stop();
		tl.to(el, 0.5, {opacity:0});
		el.data('tl', tl);
	});

	var allBtns = $('[data-sectionbtn]');
	allBtns.filter(':first').addClass('active');

	allBtns.on('click.mdg', function(e){
		e && e.preventDefault();
		if(!activeSection) return;
		var btn = $(this);
		var sectionId = btn.data('sectionbtn');
		allBtns.removeClass('active');
		btn.addClass('active');
		var onTransited = toggleSection(activeSection, allSections.filter('[data-section="'+sectionId+'"]'));
		activeSection = null;
		onTransited.then(function(section){
			//console.log(section);
			activeSection = section;
		});
	});
	
});