import { scrollToElem } from "./utils/scrollTo";

function init() {
	const headers = document.querySelectorAll('[data-video-header]');
	
	headers.forEach(header => {
		const button = header.querySelector('[data-scroll-btn]');

		if (button) {
			button.addEventListener('click', () => {
				const sibling = header.nextElementSibling;

				if (sibling) {
					scrollToElem(sibling);
				}
			});
		}
	});
}

export default {
	init,
}