//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');
var gs = require('greensock');

var $window = $(window);
var SCREEN_WIDTH;
var SCREEN_HEIGHT;
var WINDOW_CLIP_RECT;
var HEIGHT = 950 / 1920;

var SCALE = 1;
var TILE_OPEN_TIME = 0.8;

var playerOrigin = "*";

function ProductGallery(ctnEl) {
    this.ctn = $(ctnEl);
    $window.on('resize.pg', this.onResize.bind(this)).trigger('resize.pg');
    this.init();
}

ProductGallery.LAYOUTS = {
    "layout-4": [
        {
            top: 0,
            left: 0,
            width: 0.3333,
            height: HEIGHT
        },
        {
            top:0,
            left: 0.3333,
            width: 0.3333,
            height: HEIGHT / 2
        },
        {
            top:0,
            left: 0.6666,
            width: 0.3333,
            height: HEIGHT / 2
        },
        {
            top: 0.2474,
            left: 0.3333,
            width: 0.6666,
            height: HEIGHT / 2
        }
    ],
    "layout-3": [
        {
            top: 0,
            left: 0,
            width: 0.5,
            height: HEIGHT
        },
        {
            top: 0,
            left: 0.5,
            width: 0.5,
            height: HEIGHT / 2
        },
        {
            top: HEIGHT / 2,
            left: 0.5,
            width: 0.5,
            height: HEIGHT / 2
        }
    ],
    "layout-2": [
        {
            top: 0,
            left: 0,
            width: 0.5,
            height: HEIGHT
        },
        {
            top: 0,
            left: 0.5,
            width: 0.5,
            height: HEIGHT
        }
    ]
};

ProductGallery.prototype.init = function(){
    this.tiles = this.ctn.find('> div');
    this.layout = ProductGallery.LAYOUTS[this.ctn.attr('data-layout')];
    this.setupLayout();
    this.iframe = null;
    this.close = this.ctn.find('.close');
    this.title = this.ctn.find(".title");
    window.addEventListener('message', this.onMessageReceived.bind(this));

    var self = this;
    this.close.on('click.timeline', function(){
        if(self.tiles.filter('.opened').length > 0){
            self.closeTile(self.tiles.filter('.opened'));
        }
    });
};

ProductGallery.prototype.setupLayout = function() {
    var tile, clipRect;
    var self = this;
    this.layout.forEach(function(elem, index){
        tile = self.tiles.eq(index);
        clipRect = self.getClipRect(elem);
        self.setLayoutFromMarkup(tile, elem);
        tile.data('transformOrigin', { left:0, top:0 });
        tile.css({
            clip: 'rect('+ clipRect.top +'px, '+ clipRect.right +'px, '+ clipRect.bottom +'px, '+ clipRect.left +'px)',
        });
        self.centerFocusPoint(tile, clipRect);
    });

    this.tiles.on('click.pg', function(e){
        var tile = $(this);

        var targetPos = parseInt($('#gallery').offset().top - $('.anchor-menu').height());
		targetPos = $(window).width() < 1200 ? targetPos - $('.menu-bar').height() : targetPos;
		targetPos = targetPos < 0 ? 0 : targetPos;
		gs.TweenMax.to($(window), 0.6, {scrollTo:{y:targetPos}, ease:gs.Power2.easeOut});

        if(tile.hasClass('colorbox-me')){
        	return;
        }

        if(tile.hasClass('opened')) {
            self.closeTile(tile);
        } else {
            self.openTile(tile);
        }
    });

    this.tiles.on('mouseover.pg', this.onRollOver);
    this.tiles.on('mouseout.pg', this.onRollOut);
};

ProductGallery.prototype.setLayoutFromMarkup = function(tile, layout) {
    var img = tile.find('img');
    var l = {
        top: layout.top,
        left: layout.left,
        width: layout.width,
        height: layout.height,
        focalx: img.attr('data-focalx'),
        focaly: img.attr('data-focaly'),
        endfocalx: img.attr('data-endfocalx'),
        endfocaly: img.attr('data-endfocaly')
    }
    tile.data('layout', l);
};

ProductGallery.prototype.getClipRect = function(obj) {
    var cr = {};
    cr.top = ssr(obj.top);
    cr.left = ssr(obj.left);
    cr.right = cr.left + ssr(obj.width);
    cr.bottom = cr.top + ssr(obj.height);
    return cr;
};

ProductGallery.prototype.getTileLayout = function (tile) {
    var layout = {
        x: tile.data('layout').focalx,
        y: tile.data('layout').focaly
    };
    if (tile.hasClass('opened')) {
        layout.x = tile.data('layout').endfocalx;
        layout.y = tile.data('layout').endfocaly;
    }
    return layout;
};

ProductGallery.prototype.getRelativePointPosition = function(tile, clipRect){
    var img = tile.find('img');
    var layout = this.getTileLayout(tile);

    var poi = {
        x:layout.x * img.attr('data-width'),
        y:layout.y * img.attr('data-height')
    };

    var clipRectCenter = {
        x:(clipRect.left + clipRect.right) / 2,
        y:(clipRect.top + clipRect.bottom) / 2
    };

    return { x: clipRectCenter.x - poi.x, y: clipRectCenter.y - poi.y };
};

ProductGallery.prototype.centerFocusPoint = function(tile, clipRect, time, delay) {
    var focalpt = this.getRelativePointPosition(tile, clipRect);
    var layout = this.getTileLayout(tile);
    time = time || 0;
    delay = delay || 0;
    var img = tile.find('img');
    gs.TweenMax.to(img, time, {
        x: focalpt.x + 'px',
        y: focalpt.y + 'px',
        scale: SCALE,
        delay: delay,
        ease: gs.Expo.easeInOut
    });
    var transformOrigin = tile.data('transformOrigin');
    gs.TweenMax.to(transformOrigin, time, {
        left: layout.x * 100,
        top: layout.y * 100,
        delay: delay,
        onUpdate: function(){
            gs.TweenMax.set(img, { transformOrigin: transformOrigin.left + '% ' + transformOrigin.top + '%' });
            tile.data('transformOrigin', transformOrigin);
        },
        ease: gs.Expo.easeInOut
    });
};

ProductGallery.prototype.openTile = function(tile, time) {
    time = (time != undefined) ? time : TILE_OPEN_TIME;

    gs.TweenMax.to(tile, time, {
        clip: 'rect(0px, '+ SCREEN_WIDTH + 'px, '+ ssr(HEIGHT) +'px, 0px)',
        ease: gs.Expo.easeInOut
    });

    gs.TweenMax.to(this.title, time * 0.5, {
        opacity: 0,
        ease: gs.Expo.easeInOut
    });

    gs.TweenMax.to(this.close, 0.6, { autoAlpha: 1 });

    if (tile.attr('data-vimeo') && !tile.hasClass('opened')) {
        gs.TweenMax.to(tile.find('img'), time * 0.5, { alpha:0 });
        this.openIFrame(tile);
        gs.TweenMax.fromTo(this.iframe, time * 0.5, { alpha: 0 }, { alpha:1, delay: time * 0.5 });
    }

    this.tiles.css('z-index', 1);
    tile.css('z-index', 10).addClass('opened');

    this.centerFocusPoint(tile, WINDOW_CLIP_RECT, time);
};

ProductGallery.prototype.closeTile = function(tile, time) {
    time = (time != undefined) ? time : TILE_OPEN_TIME;

    var clipRect = this.getClipRect(tile.data('layout'));
    var self = this;

    var delay = 0;

    if (tile.attr('data-vimeo') && tile.hasClass('opened')) {
        delay = time * 0.5;
        gs.TweenMax.to(tile.find('img'), time, { alpha:1, delay: delay });
        gs.TweenMax.to(this.iframe, time * 0.5, { alpha:0, onComplete: function(){ self.closeIFrame(); } });
    }

    gs.TweenMax.to(this.close, 0.6, { autoAlpha: 0 });

    gs.TweenMax.to(tile, time, {
        clip: 'rect('+ clipRect.top +'px, '+ clipRect.right +'px, '+ clipRect.bottom +'px, '+ clipRect.left +'px)',
        ease: gs.Expo.easeInOut,
        delay: delay,
        onComplete: function(){
            tile.css('z-index', 1);
        }
    });

    gs.TweenMax.to(this.title, time * 0.5, {
        opacity: 1,
        ease: gs.Expo.easeInOut,
        delay: delay + (time * 0.5)
    })

    tile.removeClass('opened');
    this.centerFocusPoint(tile, clipRect, time, delay);
};

ProductGallery.prototype.onRollOver = function(){
    if(!$(this).hasClass('opened')){
        gs.TweenMax.to($(this).find('img'), 0.6, { scale:SCALE*1.2, ease:gs.Cubic.easeInOut });
    }
};

ProductGallery.prototype.onRollOut = function(){
    gs.TweenMax.to($(this).find('img'), 0.6, { scale:SCALE, ease:gs.Cubic.easeInOut });
};

ProductGallery.prototype.onResize = function() {
    SCREEN_WIDTH = $window.width();
    SCREEN_HEIGHT = $window.height();

    SCALE = SCREEN_WIDTH / 1920;

    WINDOW_CLIP_RECT = { top:0, right: SCREEN_WIDTH, bottom: ssr(HEIGHT), left:0 };

    if(this.tiles) {
        var tile, self = this;
        this.tiles.each(function(index, elem){
            tile = $(elem);
            if(tile.hasClass('opened')) {
                self.openTile(tile, 0);
            } else {
                self.closeTile(tile, 0);
            }
        });
    }
};







// PLAYER FUNCTIONS

ProductGallery.prototype.openIFrame = function(tile){
    var videoID = tile.attr('data-vimeo');
    var src = 'https://player.vimeo.com/video/'+videoID+'?autoplay=1&title=0&byline=0&portrait=0&api=1&player_id=galleryPlayer';
    if (this.iframe == null) {
        this.iframe = $('<iframe id="player1" src="'+src+'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
        this.iframe.on('click.timeline', onClickIFrame);
    } else {
        this.iframe.attr('src', src);
    }
    var self = this;
    this.iframe.appendTo(this.ctn);
};

ProductGallery.prototype.closeIFrame = function(){
    this.iframe.detach();
    this.iframe = null;
};

var onClickIFrame = function(e){
    e.preventDefault();
    return false;
};

ProductGallery.prototype.onMessageReceived = function(e){
    if (!(/^https?:\/\/player.vimeo.com/).test(e.origin)) {
        return false;
    }

    if (playerOrigin === '*') {
        playerOrigin = e.origin;
    }

    var data = JSON.parse(e.data);
    switch (data.event) {
        case 'ready':
            this.onPlayerReady();
            break;
        case 'finish':
            this.onPlayerFinished();
            break;
    }
};

ProductGallery.prototype.onPlayerReady = function () {
    this.postToVimeo('addEventListener', 'pause');
    this.postToVimeo('addEventListener', 'finish');
};

ProductGallery.prototype.onPlayerFinished = function () {
    this.closeTile(this.tiles.filter('.opened'));
};

ProductGallery.prototype.postToVimeo = function (action, value) {
    var data = {
      method: action
    };
    
    if (value) {
        data.value = value;
    }
    
    var message = JSON.stringify(data);
    this.iframe.get(0).contentWindow.postMessage(data, playerOrigin);
};








// Screen Space relative
function ssr(val) {
    return val * SCREEN_WIDTH;
}

ns.docReady.then(function(){
    $('.product-gallery').each(function(index, elem) {
        elem = $(elem);
        elem.data('productGallery', new ProductGallery(elem));
    });
});

