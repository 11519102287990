//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

require('jqui');
require('validate');


ns.docReady.then(function(){

	$("form.has-validate").validationEngine();

	//au load, affiche toutes les erreurs s'il y en a
	$('[data-error]').each(function(i, el){
		el = $(el);
		var err = el.data('error');
		if(err) el.validationEngine('showPrompt', err, null, null, true)
	});

	//remet le select de pays à la bonne value
	$('select[data-value]').each(function(i, el){
		el = $(el);
		el.val(el.data('value'));
		//console.log(el.data('value'));
		el.selectric('refresh');
	});

	var formCreate = $('#createAccount');

	//montre le formulaire au clic sur un bouton
	$('button[data-usertype]').on('click.mdg', function(){

		$('#chooseType').hide();

		var clicked = $(this);
		var type = clicked.data('usertype');
		$('#account_type').val(type);

		if(type!=='customer'){
			$('#passwordZone').hide();
		}

		var titles = formCreate.find('.form-title');
		titles.hide();
		titles.filter('.form-title-'+type).show();

		formCreate.show();

	});


	$('.datepicker').datepicker({ dateFormat: 'yy-mm-dd', changeYear:true});

});
