//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

ns.docReady.then(function(){

	//boutons qui ouvrent les formulaires
	var btns = $('button[data-action]');
	btns.on('click.mdg', function(){
		var el = $(this);
		var frmId = el.data('action');
		$('#form-'+frmId).show();
		btns.hide();
	});

});