import ns from './Namespace';

ns.docReady.then(function(){
	var hsl = $('header .slider');
	if(hsl.length > 0){
		hsl.neutrino({
			tweener: require('greensock').TweenMax,
			hasNav: true,
			isResponsive:{},
			timer: 5000
		});

		var header = $('header');

		var closeVideo = function(slide, restartTimer){
			slide.find('.cta').on('click.mdg_slidervideo', openVideo).fadeIn();

			slide.find('.close-video').fadeOut();
			slide.find('iframe').remove();
			hsl.find('nav li').off('click.mdg_slidervideo');

			header.css('height', '');
			header.css('padding-top', '');
			hsl.css('height', '');
			hsl.find('nav').show();

			if(restartTimer) {
				hsl.neutrino('resetTimer');
			}
		};

		var openVideo = function(e){
			e.preventDefault();
			hsl.neutrino('stopTimer');

			var clicked = $(this);
			clicked.off('click.mdg_slidervideo');
			var cta = clicked.closest('.cta');
			var slide = cta.closest('.slide');
			var closeBtn = slide.find('.close-video');
			var h = $(window).height() / $(window).width() > 9/16 ? $(window).width() * (9/16) : $(window).height();

			if($(window).width() <= 1199){
				var mbH = $('.menu-bar').height();
				header.height(h + mbH);
				header.css('padding-top', mbH);
				hsl.height(h);
				hsl.find('nav').hide();
			}

			slide.append('<iframe src="https://player.vimeo.com/video/' + clicked.data('vimeoid') + '?autoplay=1" width="100%" height="' + h + '" frameborder="0"></iframe>');
			header.height(h);

			cta.fadeOut();
			closeBtn.fadeIn();

			closeBtn
				.off('click.mdg_slidervideo')
				.on('click.mdg_slidervideo', function(e){
					closeVideo(slide, true);
				});

			hsl.find('nav li')
				.off('click.mdg_slidervideo')
				.on('click.mdg_slidervideo', function(e){
					closeVideo(slide);
				});
		}

		var cta = hsl.find('.cta').on('click.mdg_slidervideo', openVideo);
	}
}());
