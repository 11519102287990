//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');
require('slick-carousel');

var $window = $(window);

ns.docReady.then(function(){
    var timeline = $('.timeline');
    if(timeline.length<1) return;
    
    var frames = timeline.find('.frame');

    var MD_BREAKPOINT = 1200;
    var DESKTOP = "MDG_DESKTOP", MOBILE = "MDG_MOBILE";
    var mode = "";
    var isSlick = false;

    var retina = window.devicePixelRatio > 1;
    var chrome = window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;

    $window.on('scroll.timeline', function(){
        if(mode === DESKTOP) {
            if ($window.scrollTop() < frames.eq(0).offset().top || $window.scrollTop() > frames.eq(frames.length-1).offset().top) {
                frames.addClass('scroll');
            } else if (frames.eq(0).hasClass('scroll')) {
                frames.removeClass('scroll');
            }
        } else {
            frames.addClass('scroll');
        }
    });

    $window.on('resize.timeline', function(){
        var oldMode = mode;
        mode = ($window.width() <= MD_BREAKPOINT) ? MOBILE : DESKTOP;
        retina = window.devicePixelRatio > 1;

        if(oldMode !== mode) {
            $window.trigger('scroll');

            if (mode === DESKTOP && !(retina && chrome)) {
                if(isSlick) {
                    timeline.slick("unslick");
                }
                isSlick = false;
            } else {
                timeline.slick();
                isSlick = true;
            }
        }
    }).trigger('resize');
});
