//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

var TweenMax = require('greensock').TweenMax;
var Expo = require('greensock').Expo;

var Sticky = require('./Sticky.js');



ns.docReady.then(function(){
	var menu = $('.menu');
	var anchorMenu = $('.anchor-menu');
	var anchorMenuLinks = anchorMenu.find('a');
	
	if(anchorMenu.length > 0) {
		Sticky(anchorMenu);

		anchorMenuLinks.on('click.mdg_anchors', function(e){
			anchorMenuLinks.removeClass('active_1');
			console.log(anchorMenuLinks);
			$(e.target).addClass('active_1');
		});

		var anchors = [];
		var anchorEls = [];

		anchorMenuLinks.each(function(i, el){
			var _anchor = $($(el).attr('href'));
			
			anchorEls.push(_anchor);
			anchors.push(_anchor.offset().top);
		});

		$(window).on('scroll.mdg_anchors', function(e){
			for (var i = 0; i < anchors.length; i++) {
				var anchorPos = anchors[i];
				
				if($(window).scrollTop() >= anchorPos - 100){
					anchorMenuLinks.removeClass('active_1');
					anchorMenuLinks.eq(i).addClass('active_1');
				}
			};
		}).scroll();
	}

	// Menu mobile
	var menuBar = $('.menu-bar');
	var menuMobile = $('.menu-mobile');
	var mdgMobile = $('.mymdg-mobile');
	var siteWrap = $('.site-wrap');
	var siteOverlay = $('.site-overlay');

	var handleMobileMenu = function(e, callback){
		e.preventDefault();

		if(siteWrap.hasClass('slide-left')){
			handleMDG(e, handleMobileMenu)
			return;
		}

		menuBar.find('.menu-btn').toggleClass('active');
		menuMobile.addClass("top");
		mdgMobile.removeClass("top");
		mdgMobile.css({zIndex:2});

		if(siteWrap.hasClass('slide-right')){
			TweenMax.to(siteWrap, 0.3, {x: 0,
				ease:Expo.easeInOut,
				onComplete:function(){
					menuMobile.css({zIndex:2});
					$('.site-wrap').toggleClass('slide-right');

					if(callback){
						callback(e);
					}
				}
			});
		}
		else {
			menuMobile.css({zIndex:3});
			TweenMax.to(siteWrap, 0.3, {x: $(window).width() >= 750 ? 400 : $(window).width(), ease:Expo.easeIn,
				onComplete:function(){
					$('.site-wrap').toggleClass('slide-right');
				}
			});
		}
		
	};

	var handleMDG = function(e, callback) {
		e.preventDefault();

		if(siteWrap.hasClass('slide-right')){
			handleMobileMenu(e, handleMDG)
			return;
		}

		mdgMobile.addClass("top");
		menuMobile.removeClass("top");
		menuMobile.css({zIndex:2});

		if(siteWrap.hasClass('slide-left')){
			TweenMax.to(siteWrap, 0.3, {x: 0,
				ease:Expo.easeInOut,
				onComplete:function(){
					mdgMobile.css({zIndex:2});

					$('.site-wrap').toggleClass('slide-left');
					if(callback){
						callback(e);
					}
				}
			});
		}
		else {
			mdgMobile.css({zIndex:3});
			TweenMax.to(siteWrap, 0.3, {x: $(window).width() >= 750 ? -400 : -$(window).width(), ease:Expo.easeIn,
				onComplete:function(){
					$('.site-wrap').toggleClass('slide-left');
				}
			});
		}

	};

	menuBar.find('.menu-btn').on('click.mdg_menu', handleMobileMenu);
	menuBar.find('.mymdg').on('click.mdg_menu', handleMDG);

	menuMobile.find('.hasChildren > a').on('click.mdg_menu', function(e){
		e.preventDefault();

		var clicked = $(e.target);
		menuMobile.find('.hasChildren').not(clicked.parent()).removeClass('opened');
		menuMobile.find('.hasChildren > ul').slideUp();

		if(!clicked.parent().find('ul').is(':visible')){
			clicked.parent().find('ul').slideDown();
		}

		clicked.parent().toggleClass('opened');
	});

	// Menu desktop
	$('.right-nav').find('.mymdg-btn').on('click.mdg_menu', function(e){
		e.preventDefault();
		
		if($(e.target).hasClass('fa')){
			var mdgForm = $(e.target).parent().parent().find('.mymdg-form');
		}
		else {
			var mdgForm = $(e.target).parent().find('.mymdg-form');
		}

		mdgForm.toggle();
	});

	//met un random dans le link de logout pour pas cacher
	$('.logout-link').each(function(i, el){
		var lnk = $(el);
		lnk.attr('href', lnk.attr('href').replace('logout=1', 'logout='+(new Date()).getTime()));
	});

});
