//refactor : 2015-06-01
'use strict';

var ns = require('./Namespace.js');
var $ = require('jquery');

module.exports = function(el){
	var isFixed = false;
	var _el = el;
	var _elOriginalOffset = _el.offset();
	var _elOriginalCSS = {
		position: _el.css('position'),
		top: _el.css('top'),
		left: _el.css('left'),
		zIndex: _el.css('z-index'),
	}
	var options = {
		offsetTop: _el.data('stick-offset-top') || 0,
		offsetTopResponsive: _el.data('stick-offset-top-responsive') || 0,
		offsetSide: _el.data('stick-offset-top') || _elOriginalOffset.left
	}

	if(_el.data('stick-right')){
		options.offsetSide = $(window).width() - _el.width() - options.offsetSide;
	}

	$(window).on('scroll.sticky', function(e){
		var windowScrollTop = $(window).scrollTop();
		var cssOptions = {};
		var bodyPaddingTop= 0;

		if(windowScrollTop >= _elOriginalOffset.top){
			if(isFixed){
				return;
			}

			cssOptions = {
				position:'fixed',
				top: ($(window).width() > 1200 ? options.offsetTop : options.offsetTopResponsive) + 'px',
				left: options.offsetSide + 'px',
				width: '100%',
				zIndex:'999999'
			};

			bodyPaddingTop = el.outerHeight();
		}
		else {
			if(!isFixed){
				return;
			}

			cssOptions = _elOriginalCSS;
		}

		el.css(cssOptions);

		//$('body').css({paddingTop:bodyPaddingTop + 'px'});

		isFixed = !isFixed;
	}).scroll();

    var menu = $('.menu');
	$(window).on('resize.sticky', function(e){
		if(isFixed){
			el.css({top: ($(window).width() > 1200 ? options.offsetTop : options.offsetTopResponsive) + 'px'})
		}
		else {
			el.css({top: ($(window).width() > 1200 ? menu.height() : 50) + 'px'});
		}
	});

	return _el;
};
