//refactor : 2015-06-01
'use strict';

import gsap, { TweenMax } from 'gsap';

var ns = require('./Namespace.js');
var $ = require('jquery');

require('jqui');
require('validate');

var filters = {};
var initFilter = function(id){
	if(filters[id]) return;
	filters[id] = true;

	var noResMsg = $('#'+id+'-noresult');
	var allEls = $('[data-filterable="'+id+'"]');
	var allFilters = $('[data-filterfor="'+id+'"]');

	//tous les fields qui peuvent constituer un filtre
	var fields = [];

	allFilters.each(function(i, el){
		fields.push($(el).data('filterfield'));
	});

	//loop dans les enfants des filterables; si des filtres s'appliquent aux enfants, ils s'appliquent aussi au parent (par. ex. des docs en categorie)
	allEls.each(function(i, el){
		el = $(el);
		var children = el.find('[data-filterable="'+id+'"]');
		children.each(function(j, child){
			child = $(child);
			fields.map(function(field){
				var childVals = child.data(field);
				if(childVals) {
					var parentVals = el.data(field) || [];
					parentVals = parentVals.concat(childVals);
					el.data(field, parentVals);
				}
			});
		});
	});

	allFilters.on('change.mdg', function(){ 
		var filter = $(this);
		var showVal = parseInt(filter.val(), 10);
		var field = filter.data('filterfield'); // year or month
		var totalShown = false;

		allEls.each(function(i, el){
			el = $(el);
			var applied = el.data('filters') || {};
			var values = el.data(field);
			var shown = showVal === 0 ? true : (!values || values.indexOf(showVal) !== -1);

			applied[field] = shown;
			el.data('filters', applied) 

			var elShown = Object.keys(applied).reduce(function(carry, cur){
				return carry && applied[cur];
			}, true);
			//console.log(el.html(), totalShown);
			el[elShown?'show':'hide']();
			//console.log(elShown);
			totalShown = totalShown || elShown;
		});

		//si aucun de montré, indique "rien de trouvé"
		noResMsg[totalShown?'hide':'show']();
	});
};

function getSearch(e) {
	const search = getParams();
	const clickedElem = e.currentTarget;
	const searchedParam = clickedElem.getAttribute('data-filterfield');
	const clickedValue = clickedElem.value;
	const parsedParam = `search[${searchedParam}]`;
	search[parsedParam] = clickedValue;

	var searchObject = Object.keys(search).map(param => `{${param}:${search}}`);

	const newSearchQstr = Object.keys(search).map(param => `${param}=${search[param]}`).join('&');

	if(newSearchQstr)	window.location.search = newSearchQstr;
}

function getParams() {
	const query = window.location.search.substring(1);
	return query.split('&').reduce((all, cur) => {
		const pair = cur.split('=');
		if (pair[1]) all[pair[0]] = pair[1];
		return all;
	}, {});
}

ns.docReady.then(function(){
	var filterable = $('[data-filterable]');

	filterable.each(function(i, el){
		el = $(el);
		initFilter(el.data('filterable'));
	});
	
	const selects = document.querySelectorAll('[data-filter]');
	if(selects) {
		selects.forEach((select) => {
			select.addEventListener('change', getSearch);
		});
	}
});

