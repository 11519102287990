import { makeWhereToBuy } from '@lagrange/where-to-buy';
import firstBy from 'thenby';
import ns from './Namespace';
import { scrollToElem } from './utils/scrollTo';
import { slideUp, slideDown } from './utils/slide';
import styles from './mapStyles';

const base = '/';
const genericSort = firstBy("is_distributor", -1).thenBy("name");

function init() {
	const mapCtn = document.querySelector('[data-wtb]');
	if (!mapCtn) return;
	
	const wtb = makeWhereToBuy({
		apiKey: 'AIzaSyA7I8LgajZrYih4OMRlB_ORCa24iPe_ED8',
		placesUrl: base + '?t=partner&l=en&e=json',
		countriesUrl: base + '?t=country&l=en&e=json',
		tx: ns.wtb,
		defaultFilters: [
			(place, geoloc) => {
				if (!geoloc.address_components) return true;
				const country = geoloc.address_components.find(comp => comp.types.find(t => t === 'country')).short_name;
				return country === place.country;
			}
		],
		mapOptions: {
			styles,
			...ns.mapOptions,
		},
		parseData: function(data, google) {
			return data.map(place => {
				const radius = 5;

				let color = 'transparent';

				if (place.is_distributor) {
					color = '#005eb8';
				} else if (place.is_dealer) {
					color = '#004586';
				} else if (place.is_rental) {
					color = '#029100';
				} else if (place.is_gas_supplier) {
					color = '#d08307';
				}

				place.icon = {
					path: `M-${radius},0a${radius},${radius} 0 1,0 ${radius * 2},0a${radius},${radius} 0 1,0 -${radius * 2},0`,
					fillColor: color,
					fillOpacity: 1,
					anchor: new google.maps.Point(0,0),
					strokeWeight: 0,
					scale: 1
				};
				return place;
			});
		},
		afterFiltered: (filtered, allPlaces) => {
			if (filtered.length === 0 && allPlaces.length > 0) {
				return [allPlaces[0]];
			}
			return filtered;
		},
		filters: [
			{
				id: 'is_distributor',
				label: ns.wtb.distributors,
				func: (place) => { return place.is_distributor },
			},
			{
				id: 'is_dealer',
				label: ns.wtb.dealers,
				func: (place) => { return place.is_dealer },
			},
			{
				id: 'is_rental',
				label: ns.wtb.rentals,
				func: (place) => { return place.is_rental },
			},
			{
				id: 'is_gas_supplier',
				label: ns.wtb.gas_suppliers,
				func: (place) => { return place.is_gas_supplier },
			},
		],
		sort: (places, geoloc, filters) => {
			const sorted = [...places];
			if (geoloc.address_components) {
				const country = geoloc.address_components.find(comp => comp.types.find(t => t === 'country')).short_name;
				sorted.sort(
					firstBy((v) => {
						return v.country === country ? -1 : 1;
					})
					.thenBy("is_distributor", -1)
					.thenBy("distance")
				);
			} else {
				sorted.sort(genericSort);
			}
			return sorted;
		},
		infoWindowTemplate: (place) => {
			const socials = Object.keys(place.social).map((name, i) => {
				const nicename = name === 'googleplus' ? 'google-plus' : name;
				const href = place.social[name];
				if (!place.social[name]) return '';
				return `<a href="${href}" key="${name}" class="wtb-social-icon" target="_blank" rel="noopener noreferrer"><i class="fa fa-${nicename}"></i></a>`
			});

			const type = { name: '', class: '' };

			if (place.is_distributor) {
				type.class = 'distributor';
				type.name = 'Distributor';
			} else if (place.is_dealer) {
				type.class = 'dealer';
				type.name = 'Dealer';
			} else if (place.is_rental) {
				type.class = 'rental';
				type.name = 'Rental';
			} else if (place.is_gas_supplier) {
				type.class = 'gas_supplier';
				type.name = 'Gas Supplier';
			}

			const distance = (isNaN(place.distance)) ? '' : `<div class="wtb-iw-distance">${place.city}, ${Math.ceil(place.distance)} km away</div>`;

			return `<div class="wtb-iw-container" data-id="${place.id}" data-wtb-iw-container>
				<div class="wtb-toggle" data-wtb-toggle>
					<div class="wtb-toggle-off" style="display: none;" data-wtb-toggle-off>Close <i class="fa fa-chevron-up"></i></div>
					<div class="wtb-toggle-on" data-wtb-toggle-on>Details <i class="fa fa-chevron-down"></i></div>
				</div>
				<div class="wtb-iw-header">
					<div class="wtb-iw-logo"><img src="${place.logo}" alt="${place.name}" /></div>
					<div class="wtb-iw-excerpt">
						<div class="wtb-iw-type ${type.class}">${type.name}</div>
						<div class="wtb-iw-name">${place.name}</div>
						${distance}
					</div>
				</div>
				<div class="wtb-iw-content" data-wtb-content-toggle>
					<div class="wtb-row">
						<div class="wtb-col-left">
							<div class="wtb-section-title">Address</div>
							<div>${place.address}</div>
						</div>
						<div class="wtb-col-right">
							<div class="wtb-section-title">Contact</div>
							<div class="phone">${place.contact.phone.map(x => 'T: ' + x).join('<br/>')}</div>
							<div class="fax">${place.contact.fax.map(x => 'F: ' + x).join('<br/>')}</div>
							${place.contact.email.map(email => `<a key="${email}" href="mailto:${email}">${email}</a>`)}
						</div>
					</div>
					<div class="wtb-row">
						<div class="wtb-col-left">
							<div>
								<a target="_blank" rel="noopener noreferrer" href="${place.contact.website}">${place.contact.website}</a>
							</div>
							<div>
								<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/search/?api=1&query=${place.location.lat},${place.location.lng}">${ns.wtb.view_google_maps}</a>
							</div>
						</div>
						<div class="wtb-col-right">
							${socials.join('')}
						</div>
					</div>
				</div>
			</div>`;
		}
	}, mapCtn);

	wtb.then(comp => {
		document.querySelectorAll('[data-wtb-country], [data-wtb-filter]').forEach((elem) => {
			elem.addEventListener('click', () => {
				const country = elem.getAttribute('data-wtb-country');
				const filter = elem.getAttribute('data-wtb-filter');

				if (country) comp.setLocation(elem.getAttribute('data-wtb-country'));
				if (filter) comp.setFilters([elem.getAttribute('data-wtb-filter')]);

				scrollToElem(mapCtn);
			});
		});

		document.addEventListener('click', (e) => {
			if (e.target.hasAttribute('data-wtb-toggle-on')) {
				const container = e.target.closest('[data-wtb-iw-container]');
				const toggle = container.querySelector('[data-wtb-content-toggle]');
				slideDown(toggle);

				e.target.style.display = 'none';
				container.querySelector('[data-wtb-toggle-off]').style.display = '';
			}
			if (e.target.hasAttribute('data-wtb-toggle-off')) {
				const container = e.target.closest('[data-wtb-iw-container]');
				const toggle = container.querySelector('[data-wtb-content-toggle]');
				slideUp(toggle);

				e.target.style.display = 'none';
				container.querySelector('[data-wtb-toggle-on]').style.display = '';
			}
		});
	});
}

export default {
	init,
};
