'use strict';

import reqwest from 'reqwest';
import gsap, { TweenMax } from 'gsap';
import { scrollToElem } from './utils/scrollTo';
import { stringify } from 'querystring';

function init() {
	const btn_showMore = document.querySelector('[data-pressrelease-showmore]');
	if (btn_showMore) {
		btn_showMore.addEventListener('click', handleClick);
	}

	function handleClick(e) {

		e.preventDefault();

		const container = document.querySelector('[data-all-pressrelease]');
		let nextPage = document.querySelector('[data-nextpage]').getAttribute('data-nextpage');

		const btn_showMore = document.querySelector('[data-pressrelease-showmore]');
		const url = window.location.href + "?p=" + nextPage;

		const thisPage = nextPage-1;
		const lastPage = document.querySelector('[data-lastpage]').getAttribute('data-lastpage');

		if(thisPage < lastPage ){

			reqwest({
				url,
				method: 'get',
				success: (resp) => {

					const response = document.createElement('div');
					response.innerHTML = resp;
					const newPosts = response.querySelectorAll('[data-pressrelease]');

					const containerHeight = container.clientHeight;
					container.style.height = 'auto';

					newPosts.forEach((card)=>{
						TweenMax.to(card, 0, { opacity: 0 });
						container.appendChild(card);
					});

					const newContentHeight = container.clientHeight;

					TweenMax.fromTo(container, 0.3, {height: `${containerHeight}px`, ease:gsap.Expo.easeOut}, { height: `${newContentHeight}px`});
					TweenMax.staggerTo(newPosts, 0.7, { opacity: 1, ease: gsap.Expo.easeOut, delay: 0.2 }, 0.06);

					if (thisPage + 1 == lastPage) {
						TweenMax.to( btn_showMore, 1, {
							opacity: 0,
							onComplete: hideMoreButton()
							}
						);
					}
					else {
						let newPage = parseInt(nextPage) + 1;
						btn_showMore.setAttribute('data-nextpage', newPage);
					}
				},
			});
		}
	}

	function hideMoreButton() {
		btn_showMore.style.display = "none";
	}
}


export default {
	init,
}
