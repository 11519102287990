//eslint-disable
//refactor : 2015-06-01
/*

	<script src="{dir}/js/map.js"></script>

*/


var ns = require('./Namespace.js');
//console.log(ns);
var $ = require('jquery');

import Map from './Map';

import { TweenMax } from 'greensock';
import { scrollToElem } from '../lagrange/utils/ScrollUtils.js';

import '../lagrange/utils/jquery.writedate.js';
import 'neutrino';
import 'jquery-colorbox';
import 'selectric';
import 'jquery-lazyload';
import 'slick-carousel';
import './Portfolio.js';
import './AccountManagement.js';
import './AccountReview.js';
import './Contact.js';
import './Filters.js';
import './Menu.js';
import './ProductGallery.js';
import './SectionNavig.js';
import './Socialfeed.js';
import './Timeline.js';
import './HeaderSlider.js';
import VideoHeader from  './VideoHeader.js';
import Pressrelease from  './Pressrelease.js';


require('validate').setLanguage(ns.lang);

document.documentElement.className += (("ontouchstart" in document.documentElement) ? ' touch' : ' no-touch');

ns.docReady.then(function(){
	Map.init();

	$(".lazy").lazyload();


	try {
		$('.dateFull').writeDate({
			format: {
				fr : '%e %B %Y',
				en : '%B %e, %Y',
				es : '%e %B %Y'
			},
			lang: ns.lang
		});
	} catch(e) {
		// console.log($('.dateFull').writeDate);
	}

	(function(){
		var events = $('.event');
		if(events.length > 0){
			events.each(function(index, el){
				var _el = $(el).find('.date');

				var startDateStr = _el.data('date-start');
				var endDateStr = _el.data('date-end');
				var dates = [startDateStr, endDateStr].map($.fn.writeDate.parseStr(ns.lang));
				var dateString = '';

				if(dates[0].B !== dates[1].B) {
					if(ns.lang == 'fr'){
						dateString = dates[0].d + ' ' + dates[0].B + '-' + dates[1].d + ' ' + dates[1].B + ' ' + dates[0].Y;
					}
					else if(ns.lang == 'en'){
						dateString = dates[0].B + ' ' + dates[0].d + '-' +  dates[1].B + ' ' + dates[1].d + ', ' + dates[0].Y;
					}
					else if(ns.lang == 'es'){
						dateString = dates[0].d + ' ' + dates[0].B + '-' + dates[1].d + ' ' + dates[1].B + ' ' + dates[0].Y;
					}
				} else {
					if(ns.lang == 'fr'){
						dateString = dates[0].d + '-' + dates[1].d + ' ' + dates[0].B + ' ' + dates[0].Y;
					}
					else if(ns.lang == 'en'){
						dateString = dates[0].B + ' ' + dates[0].d + '-' + dates[1].d + ', ' + dates[0].Y;
					}
					else if(ns.lang == 'es'){
						dateString = dates[0].d + '-' + dates[1].d + ' ' + dates[0].B + ' ' + dates[0].Y;
					}
				}

				$(_el).html(dateString);
			});
		}
	}());

	(function(){
		var tsMenu = $('.side-menu.mobile');
		if (tsMenu.length > 0) {
			var toggle = tsMenu.find('.toggle');
			var menuToggle = tsMenu.find('.menu-toggle');
			toggle.on('click.mdg', function(){
				toggle.toggleClass('opened');
				menuToggle.slideToggle();
			});
		}
	}());

	(function(){
		var cb = $('.colorbox-me');
		if(cb.length > 0) {
			cb.colorbox(ns.colorboxSettings);

			$(window).on('resize.mdg', function(e){
				$.colorbox.resize();
			});
		}
	}());


	(function(){
		var sl = $('.slider-section');
		if(sl.length > 0){
			$('.slider', sl).neutrino({
				tweener: require('greensock').TweenMax,
				hasNav: true,
				hasArrows: true,
				isResponsive:{},
				timer: 5000
			});
		}
	}());

	(function(){
		var sl = $('[data-slider-home]');
		if(sl.length > 0){
			sl.slick({
				dots: true,
				autoplay: true,
				autoplaySpeed: 4000,
			});
		}
	}());


	(function(){
		var selects = $('select:not(.pressrelease_selects)');
		if(selects.length > 0) {
			selects.each(function(index, el){
				el = $(el);
				var params = {
					disableOnMobile:false,
					customClass: {
						prefix: 'selectric',
						camelCase: true,
						overwrite: true
					},
					arrowButtonMarkup: '<b class="button"><i class="fa fa-angle-down"></i></b>',
					onOpen: function(){
						//console.log(el);
						if(el.hasClass('open-on-top')) {
							var items = el.parents('.selectricWrapper').find('.selectricItems');
							items.css({top: (-1 * items.height()) + 'px'});
						}
					}
				};


				el.selectric(params);
			});
		}
	}());

	//selects qui naviguent lors du change
	$('.select-navig').on('change.mdg', function(){
		var el = $(this);
		var href = el.val();
		console.log(href);
		if(!href || !~href.indexOf('//')) return;
		if(el.hasClass('select-navig-blank')) {
			window.open(href);
		} else {
			window.location = href;
		}
	});

	//Newsletter AJAX
	(function(){
		var newsletterForm = $('#newsletter-form');
		if(newsletterForm.length > 0) {
			var nfEmail = newsletterForm.find('input[name=mce-EMAIL]');
			var nfFullName = newsletterForm.find('input[name=mce-FULLNAME]');
			var nfMessage = newsletterForm.find('.message');

			var nfMessages = {
				success: {
					fr: "Vous avez été inscrit avec succès à notre infolettre.<br/>Pour compléter le processus d'abonnement, veuillez cliquer sur le lien dans le courriel que nous venons de vous envoyer.",
					en: "You have been successfully subscribed to our newsletter.<br/>To complete the subscription process, please click the link in the email we just sent you.",
					es: "Se le ha suscrito correctamente a nuestro boletín.<br/>Para completar el proceso de suscripción, por favor haga clic en el enlace del correo electrónico que sólo le enviamos."
				},
				error: {
					fr: "Une erreur s'est produite, réponse du serveur:",
					en: "An error occurred, server response:",
					es: "Se produjo un error, respuesta del servidor:"
				}
			};

			newsletterForm.on('submit.mdg', function(e){
				e.preventDefault();

				$.ajax({
					type: "get",
					url: newsletterForm.attr('action'),
					data: newsletterForm.serialize(),
					cache : false,
					dataType : 'jsonp',
					jsonp: 'c',
					contentType: "application/json; charset=utf-8",
					error : function(err) {
						console.log("Could not connect to the registration server. Please try again later.");
					},
					success : function(data) {
						if (data.result != "success") {
							// Something went wrong, do something to notify the user. maybe alert(data.msg);
							nfMessage.removeClass("success").addClass("error").html(nfMessages.error[ns.lang] + "<br/>" +data.msg);
						} else {
							// It worked, carry on...
							nfMessage.removeClass("error").addClass("success").html(nfMessages.success[ns.lang]);
						}
						$(window).trigger('resize');
					}
				});
			});
		}
	}());





	$('.deactivated').on('click.mdg', function(e){
		e.preventDefault();
	});

	$('.scrollto').on('click.scrollto', function(e){
		e.preventDefault();
		e.stopPropagation();

		var clicked = $(e.target);
		var target = $(clicked.attr('href'));
		var duration = 0.6;
		//console.log(target.offset().top - $('.anchor-menu').height());
		// var targetPos = parseInt(target.offset().top - $('.anchor-menu').height());
		// targetPos = $(window).width() < 1200 ? targetPos - $('.menu-bar').height() : targetPos;
		// targetPos = targetPos < 0 ? 0 : targetPos;


		var offset = 20 + $('.anchor-menu').height();
		offset = $(window).width() < 1200 ? offset + $('.menu-bar').height() : offset;
		console.log(offset);
		// TweenMax.to($(window), duration, {scrollTo:{y:targetPos}, ease:require('greensock').Power2.easeOut});
		scrollToElem(target, { time: duration, offset: - offset });
	});

	if($('.specifications').length > 0) {
		var specs = $('.specifications .spec');

		$('.all-specs-btn').on('click.mdg_specs', function(e){
			e.preventDefault();
			$(e.target).toggleClass('opened');
			var invisSpecs = specs.not(specs.filter(':visible'));

			if(invisSpecs.length > 0){
				invisSpecs.slideDown();
			}
			else {
				specs.filter(':nth-child(n+7)').slideUp();
			}
		});

		var handleSpecsAccordeon = function(e){
			var _clicked = $(e.target);
			if(!_clicked.hasClass('spec-title')){
				_clicked = _clicked.parent().hasClass('spec-title') ? _clicked.parent() : _clicked.parent().parent();
			}

			if(_clicked.hasClass('opened')){
				_clicked.parent().find('.spec-content').slideUp(function(){
					_clicked.removeClass('opened');
				});
			}
			else {
				_clicked.parent().find('.spec-content').slideDown(function(){
					_clicked.addClass('opened');
				});
			}

		};

		$(window).on('resize.mdg_specs', function(e){
			if($(window).width() <= 990){
				specs.find('.spec-content');
				specs.find('.spec-title').on('click.mdg_specs', handleSpecsAccordeon);
			}
			else {
				specs.find('.spec-content').show();
				specs.find('.spec-title').off('click.mdg_specs', handleSpecsAccordeon);
			}
		}).resize();
	}

	//à refaire --- par php au lieu de js

	$('#documentsToMove').find('[data-product]').each(function(i, el){
		el = $(el);
		var prodId = el.data('product');
		$('#documents_'+prodId).replaceWith(el);
	});

	//fin a refaire


	//btns qui font toggler un element
	$('[data-togglerbtn]').on('click.mdg', function(e){
		e && e.preventDefault();
		var btn = $(this);
		var id = btn.data('togglerbtn');
		$('[data-toggler="'+id+'"]').toggle();
		$.colorbox.resize();
		return false;
	});


	if($('.job').length > 0) {
		$('.all-tasks-btn').on('click.mdg_jobs', function(e){
			e.preventDefault();

			var _clicked = $(e.target);
			var hiddenContent = _clicked.parent().parent().find('.hidden-info');

			if(!hiddenContent.is(':visible')){
				hiddenContent.slideDown();
			}
			else {
				hiddenContent.slideUp();
			}

			_clicked.toggleClass('opened');
		});
	}

	if($('.product-list').length > 0){
		var product_list = $('.product-list');
		$(window).on('resize.mdg_product_list', function(){

			if($(window).width() <= 750){
				product_list.find('.title').off('click.mdg_product_list');

				product_list.find('.title').on('click.mdg_product_list', function(e){
					var clicked = $(e.target);
					if(!clicked.hasClass('title')){
						clicked = clicked.parent();
					}

					var productGroups = clicked.parent().parent().find('.product-group');
					if(clicked.hasClass('opened')){
						productGroups.slideUp();
					}
					else {
						productGroups.slideDown();
					}

					clicked.toggleClass('opened');
				});
			}
			else {
				product_list.find('.title').off('click.mdg_product_list');
			}
		}).resize();
	}

	VideoHeader.init();
	Pressrelease.init();
}).catch(e => console.error(e));
